<script>
  import router from "page";
  import Bio from "./Bio.svelte";

  let page;

  router("/", () => router.redirect("/bio"));
  router("/bio", () => (page = Bio));
  router("*", () => router.redirect("/"));

  router.start();
</script>

<svelte:component this={page} />
